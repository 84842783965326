import './scss/style.scss'
import React, { Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './Style.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/auth/login'))
const ForgotPassword = React.lazy(() => import('./views/auth/forgotPassword'))
const ChangePassword = React.lazy(() => import('./views/auth/resetPassword'))
const Page404 = React.lazy(() => import('./views/pages/Page404'))
const Page500 = React.lazy(() => import('./views/pages/Page500'))

const App = () => {
  const loginData = localStorage.getItem('user')
  return (
    <Suspense fallback={loading}>
      <BrowserRouter fallback={loading}>
        <Routes>
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          {loginData ? (
            <>
              <Route path="*" name="Device Log" element={<DefaultLayout />} />
              <Route path="/login" element={<Navigate to="/device-log" replace />} />
            </>
          ) : (
            <>
              <Route exact path="/login" name="Login" element={<Login />} />
              <Route path="/forgot-password" name="Forgot Password" element={<ForgotPassword />} />
              <Route path="/change-password" name="Change Password" element={<ChangePassword />} />
              <Route path="*" name="Login" element={<Navigate to="/login" replace />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
